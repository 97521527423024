import gql from 'graphql-tag'

export const DEPOT_QUERY = gql`
  query Depot($id: ID!) {
    depot(id: $id) {
      name
      primaryCutOff
      secondaryCutOff
      lastMile
      line1
      line2
      line3
      zip
      city
      state
      country
      shippingDays
      shippingGroups {
        id
        name
        amTreatmentDeliveryCutOff
        minimumShippingTimeline
        preferredShippingTimeline
        sameDayShippingAvailable
        weekendShippingMinTimeline
        weekendShippingPrefTimeline
      }
    }
  }
`

export const DEPOTS_QUERY = gql`
  query Depots {
    depots {
      items {
        id
        name
      }
    }
  }
`

export const DEPOTS_ELIGIBILITY_OPTIONS_QUERY = gql`
  query DepotsEligibilityOptions {
    depots {
      items {
        id
        name
        primaryCutOff
        secondaryCutOff
        shippingGroups {
          id
          name
        }
        lastMile
      }
    }
  }
`
