export interface IconProps {
  fill?: string
  width?: string | number
  height?: string | number
  title?: string
  rotate?: number
}

 export enum DeliveryTypeEnum {
  STANDARD_DELIVERY = 'STANDARD_DELIVERY',
  EXPEDITED_DELIVERY = 'EXPEDITED_DELIVERY',
}

export enum WeekDays {
  SUNDAY = 'Su',
  MONDAY = 'Mo',
  TUESDAY = 'Tu',
  WEDNESDAY = 'We',
  THURSDAY = 'Th',
  FRIDAY = 'Fr',
  SATURDAY = 'Sa',
}
