import { OrderStatusEnum, TreatmentPlanStatusEnum } from '../__generated__/globalTypes'

interface States {
  [key: string]: string
}

export const STATES: States = {
  BUMPED: 'bumped',
  ...OrderStatusEnum
}

export const TREATMENT_PLAN_STATES: States = {
  ...TreatmentPlanStatusEnum
}
