/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessoryFreeReasonsEnum {
  S = "S",
}

export enum ActionEnum {
  CREATED = "CREATED",
  DESTROYED = "DESTROYED",
  UPDATED = "UPDATED",
}

export enum AmpouleStatusEnum {
  OTHER = "OTHER",
  SCRAP_BROKEN_AT_BWXT = "SCRAP_BROKEN_AT_BWXT",
  SCRAP_BROKEN_AT_REACTOR = "SCRAP_BROKEN_AT_REACTOR",
  SCRAP_DID_NOT_SHIP = "SCRAP_DID_NOT_SHIP",
  SCRAP_INSURANCE_NOT_USED = "SCRAP_INSURANCE_NOT_USED",
  SCRAP_OOS = "SCRAP_OOS",
}

export enum BuildPlanOpenStatusEnum {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

export enum BuildPlanStatusEnum {
  BUILT = "BUILT",
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  PENDING = "PENDING",
}

export enum CreditBillingCodeEnum {
  CODE_08 = "CODE_08",
  CODE_ZP = "CODE_ZP",
}

export enum CreditTypeEnum {
  credit_and_rebill = "credit_and_rebill",
  full_credit = "full_credit",
}

export enum CustomerBusinessTypeEnum {
  CustomerViaDistributor = "CustomerViaDistributor",
  DirectCustomer = "DirectCustomer",
  Distributor = "Distributor",
}

export enum CustomerCareCutoffTypeEnum {
  NA = "NA",
  ROW = "ROW",
  TURKEY = "TURKEY",
}

export enum EnvironmentEnum {
  snd = "snd",
  staging = "staging",
}

export enum ErrorTypes {
  AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR",
  AUTHORISATION_ERROR = "AUTHORISATION_ERROR",
  BUILD_PLAN_STATUS_CHANGE_ERROR = "BUILD_PLAN_STATUS_CHANGE_ERROR",
  DOES_NOT_EXIST = "DOES_NOT_EXIST",
  INVALID_STATE = "INVALID_STATE",
  ORDER_DATE_MISMATCH_ERROR = "ORDER_DATE_MISMATCH_ERROR",
  UNABLE_TO_CANCEL = "UNABLE_TO_CANCEL",
  UNABLE_TO_CREATE_ORDER_FOR_RESTRICTED_ACCESSORIES = "UNABLE_TO_CREATE_ORDER_FOR_RESTRICTED_ACCESSORIES",
  UNABLE_TO_CREATE_ORDER_FOR_RESTRICTED_JURISDICTION = "UNABLE_TO_CREATE_ORDER_FOR_RESTRICTED_JURISDICTION",
  UNABLE_TO_UPDATE = "UNABLE_TO_UPDATE",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  VALIDATION_ORDER_BASE = "VALIDATION_ORDER_BASE",
}

export enum FinanceStatusEnum {
  ACCEPTED_BY_FINANCE = "ACCEPTED_BY_FINANCE",
  ACCEPTED_BY_FINANCE_WITH_WARNINGS = "ACCEPTED_BY_FINANCE_WITH_WARNINGS",
  ALL = "ALL",
  CANCEL_CREDIT_REQUEST = "CANCEL_CREDIT_REQUEST",
  CREDITED = "CREDITED",
  CREDITED_IN_S4 = "CREDITED_IN_S4",
  CREDIT_APPROVED = "CREDIT_APPROVED",
  CREDIT_BLOCK_REMOVED = "CREDIT_BLOCK_REMOVED",
  CREDIT_BLOCK_REMOVED_IN_S4 = "CREDIT_BLOCK_REMOVED_IN_S4",
  CREDIT_CHECK_FAILED = "CREDIT_CHECK_FAILED",
  CREDIT_CHECK_FAILED_IN_S4 = "CREDIT_CHECK_FAILED_IN_S4",
  CREDIT_CHECK_INCOMPLETE = "CREDIT_CHECK_INCOMPLETE",
  CREDIT_CHECK_INCOMPLETE_IN_S4 = "CREDIT_CHECK_INCOMPLETE_IN_S4",
  CREDIT_CHECK_INITIATED = "CREDIT_CHECK_INITIATED",
  CREDIT_CHECK_INITIATED_IN_S4 = "CREDIT_CHECK_INITIATED_IN_S4",
  CREDIT_CHECK_SUCCESSFUL = "CREDIT_CHECK_SUCCESSFUL",
  CREDIT_CHECK_SUCCESSFUL_IN_S4 = "CREDIT_CHECK_SUCCESSFUL_IN_S4",
  CREDIT_CHECK_WARNING = "CREDIT_CHECK_WARNING",
  CREDIT_REJECTED_BY_FINANCE = "CREDIT_REJECTED_BY_FINANCE",
  CREDIT_REQUESTED = "CREDIT_REQUESTED",
  CREDIT_REQUESTED_IN_S4 = "CREDIT_REQUESTED_IN_S4",
  DEBITED_IN_S4 = "DEBITED_IN_S4",
  DEBIT_REQUESTED_IN_S4 = "DEBIT_REQUESTED_IN_S4",
  INVOICED = "INVOICED",
  NONE = "NONE",
  ORDER_CANCELLED_IN_S4 = "ORDER_CANCELLED_IN_S4",
  ORDER_CREATED_IN_S4 = "ORDER_CREATED_IN_S4",
  ORDER_CREATION_FAILED_IN_S4 = "ORDER_CREATION_FAILED_IN_S4",
  ORDER_UPDATE_FAILED_IN_S4 = "ORDER_UPDATE_FAILED_IN_S4",
  ORDER_UPDATE_SENT_TO_S4 = "ORDER_UPDATE_SENT_TO_S4",
  ORDER_UPDATE_SUCCESSFUL_IN_S4 = "ORDER_UPDATE_SUCCESSFUL_IN_S4",
  PAID = "PAID",
  PENDING_PO = "PENDING_PO",
  REJECTED_BY_FINANCE = "REJECTED_BY_FINANCE",
  SENT_TO_FINANCE = "SENT_TO_FINANCE",
  SENT_TO_S4 = "SENT_TO_S4",
  SHIPPING_UPDATE_FAILED_IN_S4 = "SHIPPING_UPDATE_FAILED_IN_S4",
  SHIPPING_UPDATE_SENT_TO_S4 = "SHIPPING_UPDATE_SENT_TO_S4",
  SHIPPING_UPDATE_SUCCESSFUL_IN_S4 = "SHIPPING_UPDATE_SUCCESSFUL_IN_S4",
}

export enum FreightTermsEnum {
  DUE = "DUE",
  PREPAID = "PREPAID",
  PREPAY_ADD = "PREPAY_ADD",
  THIRD_PARTY_BILLING = "THIRD_PARTY_BILLING",
}

export enum FreightTypeEnum {
  DEFAULT = "DEFAULT",
  DELIVERY = "DELIVERY",
}

export enum GlobalRegionCodeEnum {
  CA = "CA",
  GLOB = "GLOB",
  ROW = "ROW",
  US = "US",
}

export enum ITOSurplusStatusEnum {
  available = "available",
  pending = "pending",
}

export enum IncotermsEnum {
  CPT = "CPT",
  DAP = "DAP",
  DDP = "DDP",
  DDU = "DDU",
  FCA = "FCA",
}

export enum IncotermsYearsEnum {
  INCOTERMS_2000 = "INCOTERMS_2000",
  INCOTERMS_2010 = "INCOTERMS_2010",
}

export enum InventorySourceTypeEnum {
  BWXT_PENDING = "BWXT_PENDING",
  BWXT_STANDARD = "BWXT_STANDARD",
  BWXT_SURPLUS = "BWXT_SURPLUS",
  MNX_CA_SURPLUS = "MNX_CA_SURPLUS",
  MNX_IN_TRANSIT = "MNX_IN_TRANSIT",
  MNX_SURPLUS = "MNX_SURPLUS",
}

export enum InventoryTransferOrderStatusEnum {
  AVAILABLE = "AVAILABLE",
  BUILD_PENDING = "BUILD_PENDING",
  CANCELLED = "CANCELLED",
  CANCELLING = "CANCELLING",
  DRAFT = "DRAFT",
  IN_TRANSIT = "IN_TRANSIT",
  NOT_BUILT = "NOT_BUILT",
  PENDING_TRANSFER = "PENDING_TRANSFER",
  REJECTED = "REJECTED",
  RELEASED = "RELEASED",
}

export enum InventoryTransferStatusEnum {
  AT_MNX = "AT_MNX",
  CANCELLED = "CANCELLED",
  CANCELLED_BY_BWXT = "CANCELLED_BY_BWXT",
  CONFIMED_BY_BWXT = "CONFIMED_BY_BWXT",
  DRAFT = "DRAFT",
  IN_TRANSIT = "IN_TRANSIT",
  REQUESTED = "REQUESTED",
  REQUEST_CANCELLED = "REQUEST_CANCELLED",
  REQUEST_CONFIRMED = "REQUEST_CONFIRMED",
}

export enum ItemTypeEnum {
  SALES_INVENTORY = "SALES_INVENTORY",
  SERVICES = "SERVICES",
}

export enum ItemUsageEnum {
  ALL = "ALL",
  CLINICAL = "CLINICAL",
  COMMERCIAL = "COMMERCIAL",
}

export enum JurisdictionLabelEnum {
  CA = "CA",
  CHINA_CLINICAL = "CHINA_CLINICAL",
  ROW = "ROW",
  US = "US",
}

export enum LanguageEnum {
  en = "en",
  fr = "fr",
}

export enum MNXDepotEnum {
  MNX_CA = "MNX_CA",
  MNX_TX = "MNX_TX",
}

export enum OrderStatusEnum {
  ACCEPTED = "ACCEPTED",
  ALL = "ALL",
  BUILD_PENDING = "BUILD_PENDING",
  BUILT = "BUILT",
  CANCELLED = "CANCELLED",
  CANCELLING = "CANCELLING",
  DRAFT = "DRAFT",
  NEW = "NEW",
  REJECTED = "REJECTED",
  RELEASED = "RELEASED",
  RESERVED = "RESERVED",
  SHIPPED = "SHIPPED",
  VOID = "VOID",
}

export enum OrderTypeEnum {
  CLINICAL = "CLINICAL",
  COMMERCIAL = "COMMERCIAL",
}

export enum ProductTypeEnum {
  ACCESSORY = "ACCESSORY",
  SIMPLICITY = "SIMPLICITY",
  TREATMENT = "TREATMENT",
}

export enum ProductUsageEnum {
  M011 = "M011",
  NONE = "NONE",
}

export enum RamlCheckStatusTypeEnum {
  CHECK_COULD_NOT_BE_COMPLTED = "CHECK_COULD_NOT_BE_COMPLTED",
  CHECK_SUCCESSFUL = "CHECK_SUCCESSFUL",
  LICENSE_HOLD = "LICENSE_HOLD",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  RAML_CONNECTIVITY_ERROR = "RAML_CONNECTIVITY_ERROR",
  REQUIRES_ATTENTION_IN_RAML = "REQUIRES_ATTENTION_IN_RAML",
}

export enum ReactorCommitmentTypeEnum {
  AMPOULES = "AMPOULES",
  MASS = "MASS",
}

export enum ReactorCycleStatusEnum {
  MAINTENANCE = "MAINTENANCE",
  OPERATING = "OPERATING",
  UNPLANNED_SHUTDOWN = "UNPLANNED_SHUTDOWN",
}

export enum ReactorDeliveryDetailsTypeEnum {
  POST_CAL = "POST_CAL",
  PRE_CAL = "PRE_CAL",
}

export enum ReactorDeliveryRelativeToEnum {
  AFTER = "AFTER",
  BEFORE = "BEFORE",
  ON = "ON",
}

export enum ReasonTypeEnum {
  CANCELLATION = "CANCELLATION",
  CREDIT = "CREDIT",
  SAME_DAY_SHIPPING = "SAME_DAY_SHIPPING",
  VOID = "VOID",
}

export enum RebillBillingCodeEnum {
  CODE_09 = "CODE_09",
  CODE_ZP = "CODE_ZP",
}

export enum RegionCodeEnum {
  ALL = "ALL",
  CA = "CA",
  ROW = "ROW",
  US = "US",
}

export enum RoleEnum {
  ADMIN = "ADMIN",
  AJL_COORDINATOR = "AJL_COORDINATOR",
  BUILD_PLANNER = "BUILD_PLANNER",
  CUSTOMER_SERVICE = "CUSTOMER_SERVICE",
  FINANCE = "FINANCE",
  READ_ONLY = "READ_ONLY",
  REGIONAL_SALES_MANAGER = "REGIONAL_SALES_MANAGER",
  SALES = "SALES",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum SalesOrganizationRoutingEnum {
  sap_ecc = "sap_ecc",
  sap_s4 = "sap_s4",
  sap_s4_bridge_solution = "sap_s4_bridge_solution",
}

export enum SapReasonCodeEnum {
  D07 = "D07",
  E14 = "E14",
  G01 = "G01",
  G08 = "G08",
  J10 = "J10",
  J11 = "J11",
  J21 = "J21",
  OTH = "OTH",
  T01 = "T01",
}

export enum SimplicityFreeReasonsEnum {
  S = "S",
}

export enum SimplicityPerPlanEnum {
  LINE_ITEM = "LINE_ITEM",
  NONE = "NONE",
  PER_PLAN = "PER_PLAN",
}

export enum SortDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SourceTypeEnum {
  EMAIL_SETS = "EMAIL_SETS",
  IDOC = "IDOC",
  ORDER_FORM_EMAIL = "ORDER_FORM_EMAIL",
  ORDER_FORM_FAX = "ORDER_FORM_FAX",
  THERASPHERE360 = "THERASPHERE360",
  THERASPHERE_NOW = "THERASPHERE_NOW",
  TO_FOLLOW = "TO_FOLLOW",
  WEB_PORTAL = "WEB_PORTAL",
}

export enum SurplusEntryTypeEnum {
  ALL = "ALL",
  BWXT = "BWXT",
  BWXT_PENDING = "BWXT_PENDING",
  MNX = "MNX",
  MNX_CA = "MNX_CA",
  RESERVED_MNX = "RESERVED_MNX",
}

export enum TS360UserAccessRightEnum {
  ADMIN = "ADMIN",
  CREATOR = "CREATOR",
  EDITOR = "EDITOR",
}

export enum TeamMemberProfessionEnum {
  administrative_staff = "administrative_staff",
  finance_supply_chain = "finance_supply_chain",
  nuclear_medicine_technologist = "nuclear_medicine_technologist",
  other = "other",
  physicist = "physicist",
  radiation_safety_officer = "radiation_safety_officer",
  radiopharmacist = "radiopharmacist",
  referring_physician = "referring_physician",
  treating_physician = "treating_physician",
}

export enum TreatmentFreeReasonsEnum {
  A = "A",
  B = "B",
  C = "C",
  X = "X",
}

export enum TreatmentIntentEnum {
  HCC = "HCC",
  MCRC = "MCRC",
  OTHER = "OTHER",
}

export enum TreatmentPlanStatusEnum {
  ALL = "ALL",
  CANCELLED = "CANCELLED",
  CANCEL_REQUESTED = "CANCEL_REQUESTED",
  CONFIRMED = "CONFIRMED",
  CREATED = "CREATED",
  IN_REVIEW = "IN_REVIEW",
  RELEASED = "RELEASED",
  SHIPPED = "SHIPPED",
}

export enum TreatmentPlanTypeEnum {
  CLINICAL = "CLINICAL",
  COMMERCIAL = "COMMERCIAL",
}

export enum VersionEventEnum {
  ACCEPTED = "ACCEPTED",
  ACCEPTED_BY_FINANCE = "ACCEPTED_BY_FINANCE",
  ACCEPTED_BY_FINANCE_WITH_WARNINGS = "ACCEPTED_BY_FINANCE_WITH_WARNINGS",
  ADDED_ORDER = "ADDED_ORDER",
  ADDED_TO_BUILD_PLAN = "ADDED_TO_BUILD_PLAN",
  AT_MNX = "AT_MNX",
  AVAILABLE = "AVAILABLE",
  BUILD_PENDING = "BUILD_PENDING",
  BUILD_PENDING_VIA_ACCEPT = "BUILD_PENDING_VIA_ACCEPT",
  BUILD_PENDING_VIA_BUILD_PLAN = "BUILD_PENDING_VIA_BUILD_PLAN",
  BUILT = "BUILT",
  BUILT_AUTOMATICALLY = "BUILT_AUTOMATICALLY",
  BUILT_USING_BWXT_SURPLUS = "BUILT_USING_BWXT_SURPLUS",
  BUILT_USING_MNX_CA_SURPLUS = "BUILT_USING_MNX_CA_SURPLUS",
  BUILT_USING_MNX_SURPLUS = "BUILT_USING_MNX_SURPLUS",
  BUILT_USING_SURPLUS = "BUILT_USING_SURPLUS",
  BUILT_VIA_BUILD_PLAN = "BUILT_VIA_BUILD_PLAN",
  BUMPED = "BUMPED",
  BUMPED_ORDER = "BUMPED_ORDER",
  CANCELLED = "CANCELLED",
  CANCELLED_BUILD = "CANCELLED_BUILD",
  CANCELLED_BUILD_VIA_BUILD_PLAN = "CANCELLED_BUILD_VIA_BUILD_PLAN",
  CANCELLING = "CANCELLING",
  CANCEL_CREDIT_REQUEST = "CANCEL_CREDIT_REQUEST",
  CANCEL_REQUEST = "CANCEL_REQUEST",
  CREATE = "CREATE",
  CREDITED = "CREDITED",
  CREDITED_IN_S4 = "CREDITED_IN_S4",
  CREDIT_APPROVED = "CREDIT_APPROVED",
  CREDIT_BLOCK_REMOVED = "CREDIT_BLOCK_REMOVED",
  CREDIT_BLOCK_REMOVED_IN_S4 = "CREDIT_BLOCK_REMOVED_IN_S4",
  CREDIT_CHECK_FAILED = "CREDIT_CHECK_FAILED",
  CREDIT_CHECK_FAILED_IN_S4 = "CREDIT_CHECK_FAILED_IN_S4",
  CREDIT_CHECK_INCOMPLETE = "CREDIT_CHECK_INCOMPLETE",
  CREDIT_CHECK_INCOMPLETE_IN_S4 = "CREDIT_CHECK_INCOMPLETE_IN_S4",
  CREDIT_CHECK_INITIATED = "CREDIT_CHECK_INITIATED",
  CREDIT_CHECK_INITIATED_IN_S4 = "CREDIT_CHECK_INITIATED_IN_S4",
  CREDIT_CHECK_SUCCESSFUL = "CREDIT_CHECK_SUCCESSFUL",
  CREDIT_CHECK_SUCCESSFUL_IN_S4 = "CREDIT_CHECK_SUCCESSFUL_IN_S4",
  CREDIT_CHECK_WARNING = "CREDIT_CHECK_WARNING",
  CREDIT_DECLINED = "CREDIT_DECLINED",
  CREDIT_REJECTED_BY_FINANCE = "CREDIT_REJECTED_BY_FINANCE",
  CREDIT_REQUESTED = "CREDIT_REQUESTED",
  CREDIT_REQUESTED_IN_S4 = "CREDIT_REQUESTED_IN_S4",
  DEBITED_IN_S4 = "DEBITED_IN_S4",
  DEBIT_REQUESTED_IN_S4 = "DEBIT_REQUESTED_IN_S4",
  DESTROY = "DESTROY",
  DRAFT = "DRAFT",
  INVOICED = "INVOICED",
  IN_TRANSIT = "IN_TRANSIT",
  NONE = "NONE",
  NOT_BUILT = "NOT_BUILT",
  ORDER_CANCELLED_IN_S4 = "ORDER_CANCELLED_IN_S4",
  ORDER_CREATED_IN_S4 = "ORDER_CREATED_IN_S4",
  ORDER_CREATION_FAILED_IN_S4 = "ORDER_CREATION_FAILED_IN_S4",
  ORDER_UPDATE_FAILED_IN_S4 = "ORDER_UPDATE_FAILED_IN_S4",
  ORDER_UPDATE_SENT_TO_S4 = "ORDER_UPDATE_SENT_TO_S4",
  ORDER_UPDATE_SUCCESSFUL_IN_S4 = "ORDER_UPDATE_SUCCESSFUL_IN_S4",
  PENDING_PO = "PENDING_PO",
  PENDING_TRANSFER = "PENDING_TRANSFER",
  RAML_CHECK_FAILED = "RAML_CHECK_FAILED",
  REJECTED = "REJECTED",
  REJECTED_BY_FINANCE = "REJECTED_BY_FINANCE",
  RELEASED = "RELEASED",
  REMOVED_CREDIT = "REMOVED_CREDIT",
  REMOVED_FROM_BUILD_PLAN = "REMOVED_FROM_BUILD_PLAN",
  REMOVED_ORDER = "REMOVED_ORDER",
  REQUESTED = "REQUESTED",
  REQUEST_CANCELLED = "REQUEST_CANCELLED",
  REQUEST_CONFIRMED = "REQUEST_CONFIRMED",
  RESERVED = "RESERVED",
  REVERT_BUILD_PLAN_TO_DRAFT = "REVERT_BUILD_PLAN_TO_DRAFT",
  REVERT_TO_ACCEPTED_VIA_BUILD_PLAN = "REVERT_TO_ACCEPTED_VIA_BUILD_PLAN",
  SENT_TO_FINANCE = "SENT_TO_FINANCE",
  SENT_TO_S4 = "SENT_TO_S4",
  SHIPPED = "SHIPPED",
  SHIPPING_UPDATE_FAILED_IN_S4 = "SHIPPING_UPDATE_FAILED_IN_S4",
  SHIPPING_UPDATE_SENT_TO_S4 = "SHIPPING_UPDATE_SENT_TO_S4",
  SHIPPING_UPDATE_SUCCESSFUL_IN_S4 = "SHIPPING_UPDATE_SUCCESSFUL_IN_S4",
  SUBMITTED = "SUBMITTED",
  UPDATE = "UPDATE",
  UPLOADED_TO_BWXT = "UPLOADED_TO_BWXT",
  VOID = "VOID",
}

export enum VersionItemTypeEnum {
  ADDRESS = "ADDRESS",
  AMPOULE_ALLOCATION = "AMPOULE_ALLOCATION",
  AMPOULE_ALLOCATION_DELIVERY = "AMPOULE_ALLOCATION_DELIVERY",
  AMPOULE_ALLOCATION_PLAN = "AMPOULE_ALLOCATION_PLAN",
  AMPOULE_LOG = "AMPOULE_LOG",
  AMPOULE_SCHEDULING = "AMPOULE_SCHEDULING",
  BUILD_PLAN = "BUILD_PLAN",
  CALIBRATION_DATE = "CALIBRATION_DATE",
  COUNTRY_CONFIGURATION = "COUNTRY_CONFIGURATION",
  CUSTOMER = "CUSTOMER",
  DISTRIBUTOR = "DISTRIBUTOR",
  INVENTORY_TRANSFER = "INVENTORY_TRANSFER",
  INVENTORY_TRANSFER_ORDER = "INVENTORY_TRANSFER_ORDER",
  JURISDICTION = "JURISDICTION",
  ORDER = "ORDER",
  PORTAL_USER = "PORTAL_USER",
  PRODUCT = "PRODUCT",
  QUOTE = "QUOTE",
  REACTOR = "REACTOR",
  REASON = "REASON",
  SALES_ORGANIZATION = "SALES_ORGANIZATION",
  SAP_BILLING_DETAIL = "SAP_BILLING_DETAIL",
  TEAM_MEMBER = "TEAM_MEMBER",
  USER = "USER",
}

export interface AccessoryInput {
  id?: string | null;
  productId: string;
  quantity: number;
  freeReason?: AccessoryFreeReasonsEnum | null;
  lineItemNumber: any;
  _destroy?: boolean | null;
}

export interface AllottedDosesInputs {
  doseSize?: string | null;
  surplusStatus?: string | null;
}

export interface AlterDoseInput {
  id: string;
  regionCode: RegionCodeEnum;
  dosage: number;
}

export interface AmpouleInput {
  id: string;
  name: string;
  mass: number;
  massTransfer: number;
  sa: number;
  reactorId?: number | null;
  reactorName?: string | null;
}

export interface AttachmentFileInput {
  fileId: string;
  filename: string;
  contentType: string;
  size: number;
}

export interface AttachmentInput {
  id: string;
  _destroy?: boolean | null;
}

export interface BumpableOrderFiltersInput {
  calibrationDate: any;
  regionCode: RegionCodeEnum;
  dosages: number[];
}

export interface CancelInventoryTransferOrderInput {
  inventoryTransferId: string;
  inventoryTransferOrderId: string;
}

export interface CancelOrderInput {
  id: string;
  cancellationReasonId: string;
}

export interface CancellationReasonFiltersInput {
  page: number;
  limit: number;
}

export interface CheckTreatmentInput {
  calibrationDate: any;
  productId: string;
}

export interface CommentableInput {
  commentableId: string;
  commentableType: string;
}

export interface ContactInput {
  id?: string | null;
  email?: string | null;
  name?: string | null;
  notify?: boolean | null;
  phone?: string | null;
  receiveOrderNotification?: boolean | null;
  receiveShippingNotification?: boolean | null;
  _destroy?: boolean | null;
}

export interface CreateAddressInput {
  adminSetIds?: string[] | null;
  contacts?: ContactInput[] | null;
  customerId: string;
  freightTerms?: FreightTermsEnum | null;
  gpAddressCode?: string | null;
  incoterms?: IncotermsEnum | null;
  incotermsLocation?: string | null;
  incotermsYear?: IncotermsYearsEnum | null;
  line1: string;
  line2?: string | null;
  line3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  shippingNote?: string | null;
  mnxShippingNote?: string | null;
  nordionShipToId: string;
  attnTo?: string | null;
  attnTo2?: string | null;
  sapShipTo: string;
  mnxCustomer?: boolean | null;
  mnxEligibleForRush?: boolean | null;
  deliveryChargeIds: string[];
  customerFriendlyName: string;
  timezone: string;
  depotEligibilities: DepotEligibilityInput[];
}

export interface CreateCustomerInput {
  financeId: string;
  name: string;
  regionId: string;
  currencyCode: string;
  nordionAccountNumber?: string | null;
  quote?: QuoteInput | null;
  purchaseOrder?: PurchaseOrderInput | null;
  assignedUserIds?: string[] | null;
  sapBillingDetail?: SapBillingDetailInput | null;
  freightPrice?: number | null;
  billingBlock?: boolean | null;
  simplicityPerPlan?: SimplicityPerPlanEnum | null;
  sla?: string | null;
  sendOrdersToSap?: boolean | null;
  distributorId?: number | null;
  inheritAddressesFromDistributor?: boolean | null;
  inheritFinancialInfoFromDistributor?: boolean | null;
  businessType?: CustomerBusinessTypeEnum | null;
  country: string;
  alertData?: string | null;
  specialDeal?: string | null;
  bwxtShippingNote?: string | null;
  ts360Migrated?: boolean | null;
}

export interface CreateInput {
  commentableId: string;
  commentableType: string;
  rawContent: any;
}

export interface CreateOrderInput {
  sameDayShipping?: boolean | null;
  sameDayShippingReasonId?: string | null;
  customerId: string;
  distributorId?: string | null;
  addressId: string;
  distributorAddressId?: string | null;
  orderDate: any;
  treatmentDate: any;
  treatmentTime: string;
  shipDate: any;
  deliveryDate?: any | null;
  deliveryTime?: string | null;
  patientRef?: string | null;
  poNumber?: string | null;
  shippingNote?: string | null;
  mnxShippingNote?: string | null;
  contacts?: ContactInput[] | null;
  orderType: OrderTypeEnum;
  useBwxtSurplusInventory?: boolean | null;
  useMnxSurplusInventory?: boolean | null;
  useMnxCaSurplusInventory?: boolean | null;
  useMnxReservedSurplusInventory?: boolean | null;
  treatments?: TreatmentInput[] | null;
  accessories?: AccessoryInput[] | null;
  simplicities?: SimplicityInput[] | null;
  attachmentsFiles?: AttachmentFileInput[] | null;
  sourceType?: SourceTypeEnum | null;
  invoiceNotes?: string | null;
  billingBlock?: boolean | null;
  invoiceNotesCredit?: string | null;
  invoiceNotesDebit?: string | null;
  internalComments?: string | null;
  notForSap?: boolean | null;
  sapBillingCode?: string | null;
  mnxDeliveryContactName?: string | null;
  mnxDeliveryContactPhoneNumber?: string | null;
  deliveryChargeMessage?: string | null;
  dockDate?: any | null;
  dockTime?: string | null;
  alertData?: string | null;
  specialDeal?: string | null;
  treatingPhysicianName?: string | null;
  treatingPhysicianId?: string | null;
  referringPhysicianId?: string | null;
  referringPhysicianName?: string | null;
  treatmentIntent?: TreatmentIntentEnum | null;
  treatmentId?: string | null;
}

export interface CreatePortalUserInput {
  firstName: string;
  lastName: string;
  email: string;
  canCreateOrders: boolean;
  canEditOrders: boolean;
  customerId: string;
  addressIds: string[];
  preferredLanguage: LanguageEnum;
  teamMemberAttributes: CreateTeamMemberInput;
}

export interface CreateProductInput {
  itemNumber: string;
  itemDescription: string;
  sapMaterialNumber: string;
  itemType: ItemTypeEnum;
  additionalInfo?: string | null;
  itemUsageType: ItemUsageEnum;
  productType: ProductTypeEnum;
  dosage?: number | null;
  productUse: ProductUsageEnum;
  freight?: boolean | null;
  freightType?: FreightTypeEnum | null;
  regionId: string;
}

export interface CreateReasonInput {
  type: ReasonTypeEnum;
  code: string;
  description: string;
  customerFacingDescription?: string | null;
  showInPortal?: boolean | null;
  sapCode?: SapReasonCodeEnum | null;
  creditBillingCode?: CreditBillingCodeEnum | null;
  rebillBillingCode?: RebillBillingCodeEnum | null;
}

export interface CreateSalesOrganizationInput {
  countries: string[];
  code: string;
  currencies: string[];
  sapRouting: SalesOrganizationRoutingEnum;
  sapS4LimitedRisk: boolean;
  freightAtHeaderLevel: boolean;
  creditCheckNotificationEmails?: CreditCheckNotificationEmailsInput | null;
}

export interface CreateShippingGroupInput {
  depotId: string;
  name: string;
  amTreatmentDeliveryCutOff?: string | null;
  minimumShippingTimeline?: number | null;
  preferredShippingTimeline?: number | null;
  sameDayShippingAvailable?: boolean | null;
  weekendShippingPrefTimeline?: boolean | null;
  weekendShippingMinTimeline?: boolean | null;
}

export interface CreateTS360UserInput {
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  addressIds: string[];
  isTreatingPhysician: boolean;
  accessRights: TS360UserAccessRightEnum;
  glassServicesUpdates: boolean;
  orderUpdates: boolean;
}

export interface CreateTeamMemberInput {
  firstName: string;
  lastName: string;
  email?: string | null;
  profession: TeamMemberProfessionEnum;
  otherProfession?: string | null;
  npi?: string | null;
  customerId: string;
}

export interface CreateTreatmentInput {
  sameDayShipping?: boolean | null;
  sameDayShippingReasonId?: string | null;
  customerId: string;
  addressId: string;
  treatmentDate: any;
  treatmentTime: string;
  distributorId?: string | null;
  distributorAddressId?: string | null;
  treatmentReference?: string | null;
  poNumber?: string | null;
  treatingPhysicianId?: string | null;
  referringPhysicianId?: string | null;
  referringPhysicianName?: string | null;
  treatmentIntent?: string | null;
  treatmentType?: TreatmentPlanTypeEnum | null;
  treatmentSource: SourceTypeEnum;
  shipDate: any;
  deliveryDate?: any | null;
  deliveryTime?: string | null;
  dockDate?: any | null;
  dockTime?: string | null;
  alertData?: string | null;
  specialDeal?: string | null;
  sapBillingCode?: string | null;
  invoiceNotes?: string | null;
  week1?: VialInput[] | null;
  week2?: VialInput[] | null;
  accessories?: AccessoryInput[] | null;
  attachmentsAttributes?: AttachmentFileInput[] | null;
  manualMode?: boolean | null;
}

export interface CreateUserInput {
  firstName: string;
  lastName: string;
  email: string;
  roleIds: RoleEnum[];
  subordinateIds?: string[] | null;
  managerIds?: string[] | null;
}

export interface CreditCheckNotificationEmailsInput {
  creditCheckIncomplete?: CreditCheckNotificationInput | null;
  creditCheckFailed?: CreditCheckNotificationInput | null;
  creditWarningReceived?: CreditCheckNotificationInput | null;
  creditBlockRemoved?: CreditCheckNotificationInput | null;
  orderVoidedAfterCreditCheck?: CreditCheckNotificationInput | null;
}

export interface CreditCheckNotificationInput {
  from: string;
  to?: string[] | null;
  cc?: string[] | null;
  bcc?: string[] | null;
}

export interface CreditReasonFiltersInput {
  page: number;
  limit: number;
}

export interface CurrentTreatmentInput {
  treatments: CheckTreatmentInput[];
  surplusType: SurplusEntryTypeEnum;
}

export interface CustomerFiltersInput {
  search: string;
  withoutContacts: boolean;
  page: number;
  limit: number;
  businessType: CustomerBusinessTypeEnum[];
}

export interface DateRangeInput {
  from?: any | null;
  to?: any | null;
}

export interface DepotEligibilityInput {
  id?: string | null;
  depotId: string;
  eligibility: boolean;
  shippingGroupId?: string | null;
  priority?: number | null;
  depotCutOff?: number | null;
  lastMile?: boolean | null;
}

export interface EmailChangeInput {
  token: string;
}

export interface ExportBWXTInput {
  from?: any | null;
  to?: any | null;
}

export interface ExportBuildPlanInput {
  statuses: string[];
  calibrationDate?: any | null;
}

export interface ExportCreditCheckInput {
  sapSalesOrgOptions: string[];
  from?: any | null;
  to?: any | null;
}

export interface ExportFinanceInput {
  regionCode: RegionCodeEnum;
  from?: any | null;
  to?: any | null;
}

export interface ExportOrdersInput {
  regionCode: GlobalRegionCodeEnum;
  from?: any | null;
  to?: any | null;
}

export interface ExportReactorsInput {
  reactors?: string[] | null;
  from?: any | null;
  to?: any | null;
}

export interface ExportSimplicityInput {
  regionCode: RegionCodeEnum;
  from?: any | null;
  to?: any | null;
}

export interface HistoryInput {
  itemId: string;
  itemType: string;
}

export interface InventoryTransferOrderInput {
  inventoryTransferId: string;
  inventoryTransferOrderId: string;
}

export interface JurisdictionFiltersInput {
  page: number;
  limit: number;
  sort: SortInput;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface LotNumberInput {
  id: string;
  nordionLotNumber: string;
}

export interface NewPasswordInput {
  token: string;
  password: string;
  passwordConfirmation: string;
}

export interface OrderCreditInput {
  id: string;
  financeStatus: FinanceStatusEnum;
  creditReasonId?: string | null;
  creditType?: CreditTypeEnum | null;
  rebillCurrencyCode?: string | null;
  rebills?: RebillInput[] | null;
  invoiceNotesCredit?: string | null;
  invoiceNotesDebit?: string | null;
  internalComments?: string | null;
  rebillSimplicityPerPlan?: SimplicityPerPlanEnum | null;
  sapBillingCodeDebit?: string | null;
}

export interface OrderFiltersInput {
  search: string;
  status: OrderStatusEnum;
  financeStatus: FinanceStatusEnum;
  sort: SortInput;
  page: number;
  limit: number;
  poBlank: boolean;
  countries: string[];
}

export interface OrderInvoiceInput {
  id: string;
  invoiceNumber: string;
  invoiceTotalAmount: string;
  poNumber?: string | null;
  sapCurrencyCode?: string | null;
}

export interface PasswordResetInput {
  email: string;
}

export interface PortalAdminPanelFiltersInput {
  page: number;
  limit: number;
  sort: SortInput;
}

export interface PortalUserFiltersInput {
  page: number;
  limit: number;
  sort: SortInput;
}

export interface PotentialOrdersFilterTypeInput {
  region?: string | null;
  status?: string | null;
  orderNumber?: string | null;
  dosage?: string | null;
}

export interface ProductFiltersInput {
  search: string;
  page: number;
  limit: number;
}

export interface PurchaseOrderInput {
  reference?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  price?: string | null;
}

export interface QuoteInput {
  reference?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  price?: string | null;
}

export interface ReactorCommitmentInput {
  id?: string | null;
  lineItemNumber: any;
  commitmentType: ReactorCommitmentTypeEnum;
  ampouleMass?: string | null;
  weeklyTarget?: number | null;
  annualCommitment: number;
  startDate: any;
  endDate: any;
  _destroy?: boolean | null;
}

export interface ReactorCycleInput {
  id?: string | null;
  lineItemNumber: any;
  reference?: string | null;
  status: ReactorCycleStatusEnum;
  startDate: any;
  endDate: any;
  _destroy?: boolean | null;
}

export interface ReactorDeliveryDetailsInput {
  id?: string | null;
  lineItemNumber: any;
  deliveryDetailsType: ReactorDeliveryDetailsTypeEnum;
  flightDays: number;
  relativeToFlight: ReactorDeliveryRelativeToEnum;
  note: string;
  receiveDays?: number | null;
  relativeToReceive: ReactorDeliveryRelativeToEnum;
  receiveTime: string;
  _destroy?: boolean | null;
}

export interface RebillInput {
  id?: string | null;
  productOrderId: string;
  amount?: string | null;
  included: boolean;
}

export interface S4OrderRetryInput {
  id: string;
  financeStatus: FinanceStatusEnum;
}

export interface SalesOrganizationFiltersInput {
  page: number;
  limit: number;
  sort: SortInput;
}

export interface SameDayShippingReasonFiltersInput {
  page: number;
  limit: number;
}

export interface SapBillingDetailInput {
  poRequired?: boolean | null;
  sapSoldTo?: string | null;
  sapPayer?: string | null;
  sapBillTo?: string | null;
  invoiceNotes?: string | null;
  sapSalesOrg?: string | null;
  sapBillingCode?: string | null;
}

export interface ShipOrderInput {
  id: string;
  actualShipDate: any;
  carrier?: string | null;
  waybillNumber?: string | null;
}

export interface SimplicityInput {
  id?: string | null;
  productId: string;
  quantity: number;
  freeReason?: SimplicityFreeReasonsEnum | null;
  lineItemNumber: any;
  _destroy?: boolean | null;
}

export interface SortInput {
  column: string;
  direction: SortDirectionEnum;
}

export interface SurplusFiltersInput {
  calibrationDate?: any | null;
  regionCode: RegionCodeEnum;
}

export interface SurplusInput {
  calibrationDate: any;
  regionCode: RegionCodeEnum;
  surplusType: SurplusEntryTypeEnum;
  dosage: number;
  quantity: number;
  mnxDepot?: MNXDepotEnum | null;
}

export interface TMOSInput {
  id: string;
  tmos: any;
}

export interface TreatmentInput {
  id?: string | null;
  productId: string;
  quantity: number;
  calibrationDate: any;
  freeReason?: TreatmentFreeReasonsEnum | null;
  lineItemNumber: any;
  _destroy?: boolean | null;
  activityAtDelivery?: string | null;
}

export interface TreatmentPlanFiltersInput {
  search: string;
  status: TreatmentPlanStatusEnum;
  sort: SortInput;
  page: number;
  limit: number;
  countries: string[];
}

export interface TreatmentPlanFulfillmentDose {
  calibrationDate: any;
  dosage: number;
}

export interface UpdateAddressInput {
  adminSetIds?: string[] | null;
  contacts?: ContactInput[] | null;
  customerId: string;
  freightTerms?: FreightTermsEnum | null;
  gpAddressCode?: string | null;
  id: string;
  incoterms?: IncotermsEnum | null;
  incotermsLocation?: string | null;
  incotermsYear?: IncotermsYearsEnum | null;
  line1: string;
  line2?: string | null;
  line3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  shippingNote?: string | null;
  mnxShippingNote?: string | null;
  nordionShipToId: string;
  attnTo?: string | null;
  attnTo2?: string | null;
  sapShipTo: string;
  mnxCustomer?: boolean | null;
  mnxEligibleForRush?: boolean | null;
  deliveryChargeIds: string[];
  customerFriendlyName: string;
  timezone: string;
  depotEligibilities: DepotEligibilityInput[];
}

export interface UpdateAmpouleAllocationDeliveriesInput {
  deliveries: UpdateAmpouleAllocationDeliveryInput[];
}

export interface UpdateAmpouleAllocationDeliveryInput {
  id?: string | null;
  reactorId: number;
  calibrationDateId: number;
  preOrPostCal?: string | null;
  flightDate?: any | null;
  expectedReceiveDate?: any | null;
  expectedReceiveTime?: string | null;
  carrierNote?: string | null;
}

export interface UpdateAmpouleAllocationInput {
  id?: string | null;
  lineItemNumber: any;
  ampouleSize: string;
  reactorId: number;
  ampouleAllocationPlanId: number;
  calibrationDate: any;
}

export interface UpdateAmpouleAllocationsInput {
  allocations: UpdateAmpouleAllocationInput[];
}

export interface UpdateAmpouleLogInput {
  id?: string | null;
  buildPlanId?: number | null;
  ampouleName?: string | null;
  ampouleStatus?: AmpouleStatusEnum | null;
  details?: string | null;
}

export interface UpdateAmpouleLogsInput {
  logs: UpdateAmpouleLogInput[];
}

export interface UpdateAmpouleSchedulingInput {
  id: string;
  qty3G: number;
  qty45G: number;
  qtyTotal: number;
  qtyContainers: number;
  totalMass: string;
  totalGbq: string;
  status: string;
}

export interface UpdateBuildPlanInput {
  ampoules?: AmpouleInput[] | null;
  calVialsSa?: number | null;
  minDispensingLimit?: number | null;
  maxDispensingLimit?: number | null;
  nordionLotNumber?: string | null;
  mnxSpareShipDate?: any | null;
  mnxSpareExpectedDeliveryDate?: any | null;
  itoSubmitted?: boolean | null;
}

export interface UpdateCountryConfigurationInput {
  id: string;
  isEnabled?: boolean | null;
  amTreatmentWindow?: string | null;
  minimumBufferDays?: number | null;
  amTreatmentOption?: boolean | null;
  defaultMessageEnabled?: boolean | null;
  alertMessage?: string | null;
  privacyNoticeLink?: string | null;
  termsOfUseLink?: string | null;
  copyrightNoticeLink?: string | null;
  portalRegistrationLink?: string | null;
  customerCareCutOff?: CustomerCareCutoffTypeEnum | null;
}

export interface UpdateCreditOrderInput {
  id: string;
  sapCreditMemoNumber: string;
  sapCreditRequestNumber: string;
  sapRebillRequestNumber?: string | null;
  creditTotalAmount: string;
  creditCurrencyCode: string;
  rebillTotalAmount?: string | null;
  rebillCurrencyCode?: string | null;
}

export interface UpdateCurrentUserInput {
  firstName: string;
  lastName: string;
  email: string;
  timeZone: string;
  password?: string | null;
  passwordConfirmation?: string | null;
  currentPassword: string;
}

export interface UpdateCustomerAttachmentsInput {
  id: string;
  attachments?: AttachmentInput[] | null;
  attachmentsFiles?: AttachmentFileInput[] | null;
}

export interface UpdateCustomerInput {
  id: string;
  financeId: string;
  name: string;
  regionId: string;
  currencyCode: string;
  nordionAccountNumber?: string | null;
  quote?: QuoteInput | null;
  purchaseOrder?: PurchaseOrderInput | null;
  assignedUserIds?: string[] | null;
  preferredAddressId?: string | null;
  sapBillingDetail?: SapBillingDetailInput | null;
  freightPrice?: number | null;
  billingBlock?: boolean | null;
  simplicityPerPlan?: SimplicityPerPlanEnum | null;
  sla?: string | null;
  sendOrdersToSap?: boolean | null;
  attachments?: AttachmentInput[] | null;
  attachmentsFiles?: AttachmentFileInput[] | null;
  distributorId?: number | null;
  inheritAddressesFromDistributor?: boolean | null;
  inheritFinancialInfoFromDistributor?: boolean | null;
  businessType?: CustomerBusinessTypeEnum | null;
  country: string;
  bwxtShippingNote?: string | null;
  alertData?: string | null;
  specialDeal?: string | null;
  ts360Migrated?: boolean | null;
}

export interface UpdateDepotInput {
  id: string;
  city?: string | null;
  country?: string | null;
  line1?: string | null;
  line2?: string | null;
  line3?: string | null;
  name?: string | null;
  primaryCutOff?: string | null;
  secondaryCutOff?: string | null;
  state?: string | null;
  zip?: string | null;
}

export interface UpdateInput {
  id?: string | null;
  rawContent: any;
}

export interface UpdateInventoryTransferOrderInput {
  itoNumber: string;
  shipDate?: any | null;
  expectedArrivalDate?: any | null;
  expectedArrivalTime?: string | null;
  nordionRef?: string | null;
  carrier?: string | null;
  waybillNumber?: string | null;
  packageNumber?: string | null;
  activityMeasure?: string | null;
  transportIndex?: string | null;
  lotNumber?: string | null;
  mnxAsnNumber?: string | null;
  regionCode?: string | null;
}

export interface UpdateJurisdictionInput {
  id: string;
  labellingUsed?: JurisdictionLabelEnum | null;
  restrictions?: string | null;
  commerciallyAvailable?: boolean | null;
  marketAuthorizationObtained?: boolean | null;
  active?: boolean | null;
  jurisdictionProductIds?: string[] | null;
}

export interface UpdateOrderInput {
  id: string;
  addressId: string;
  distributorId?: string | null;
  distributorAddressId?: string | null;
  orderDate: any;
  treatmentDate: any;
  treatmentTime: string;
  shipDate: any;
  deliveryDate?: any | null;
  deliveryTime?: string | null;
  patientRef?: string | null;
  poNumber?: string | null;
  shippingNote?: string | null;
  mnxShippingNote?: string | null;
  nordionRef?: string | null;
  shippingLink?: string | null;
  contacts?: ContactInput[] | null;
  orderType: OrderTypeEnum;
  useBwxtSurplusInventory?: boolean | null;
  useMnxSurplusInventory?: boolean | null;
  useMnxCaSurplusInventory?: boolean | null;
  useMnxReservedSurplusInventory?: boolean | null;
  treatments?: TreatmentInput[] | null;
  accessories?: AccessoryInput[] | null;
  simplicities?: SimplicityInput[] | null;
  attachments?: AttachmentInput[] | null;
  attachmentsFiles?: AttachmentFileInput[] | null;
  sourceType?: SourceTypeEnum | null;
  invoiceNotes?: string | null;
  billingBlock?: boolean | null;
  sapBillingCode?: string | null;
  mnxDeliveryContactName?: string | null;
  mnxDeliveryContactPhoneNumber?: string | null;
  sameDayShipping?: boolean | null;
  sameDayShippingReasonId?: string | null;
  deliveryChargeMessage?: string | null;
  dockDate?: any | null;
  dockTime?: string | null;
  treatingPhysicianName?: string | null;
  treatingPhysicianId?: string | null;
  referringPhysicianName?: string | null;
  referringPhysicianId?: string | null;
  treatmentIntent?: TreatmentIntentEnum | null;
  treatmentId?: string | null;
}

export interface UpdatePoNumberInput {
  id: string;
  poNumber: string;
  treatingPhysicianName?: string | null;
  treatingPhysicianId?: string | null;
  referringPhysicianName?: string | null;
  referringPhysicianId?: string | null;
}

export interface UpdatePortalUserInput {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  canCreateOrders: boolean;
  canEditOrders: boolean;
  customerId: string;
  addressIds: string[];
  teamMemberAttributes: UpdateTeamMemberInput;
  preferredLanguage: LanguageEnum;
}

export interface UpdateProductInput {
  id: string;
  itemNumber: string;
  itemDescription: string;
  sapMaterialNumber: string;
  itemType: ItemTypeEnum;
  additionalInfo?: string | null;
  itemUsageType: ItemUsageEnum;
  productType: ProductTypeEnum;
  dosage?: number | null;
  productUse: ProductUsageEnum;
  freight?: boolean | null;
  freightType?: FreightTypeEnum | null;
  regionId: string;
}

export interface UpdateReactorInput {
  id: string;
  reactorCycles: ReactorCycleInput[];
  reactorCommitments: ReactorCommitmentInput[];
  irradiationWeek: any;
  qtyPerContainer: any;
  reactorDeliveryDetails: ReactorDeliveryDetailsInput[];
}

export interface UpdateReasonInput {
  id: string;
  code: string;
  description: string;
  customerFacingDescription?: string | null;
  showInPortal?: boolean | null;
  sapCode?: SapReasonCodeEnum | null;
  creditBillingCode?: CreditBillingCodeEnum | null;
  rebillBillingCode?: RebillBillingCodeEnum | null;
}

export interface UpdateSalesOrganizationInput {
  id: string;
  countries: string[];
  code: string;
  currencies: string[];
  sapRouting: SalesOrganizationRoutingEnum;
  sapS4LimitedRisk: boolean;
  freightAtHeaderLevel: boolean;
  creditCheckNotificationEmails?: CreditCheckNotificationEmailsInput | null;
}

export interface UpdateShippingGroupInput {
  id: string;
  name: string;
  amTreatmentDeliveryCutOff?: string | null;
  minimumShippingTimeline?: number | null;
  preferredShippingTimeline?: number | null;
  sameDayShippingAvailable?: boolean | null;
  weekendShippingPrefTimeline?: boolean | null;
  weekendShippingMinTimeline?: boolean | null;
}

export interface UpdateTeamMemberInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  profession?: TeamMemberProfessionEnum | null;
  otherProfession?: string | null;
  npi?: string | null;
  removalRequestedAt?: any | null;
}

export interface UpdateTreatmentPlanAttachmentsInput {
  id: string;
  attachments?: AttachmentInput[] | null;
  attachmentsFiles?: AttachmentFileInput[] | null;
}

export interface UpdateUserInput {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roleIds: RoleEnum[];
  subordinateIds?: string[] | null;
  managerIds?: string[] | null;
}

export interface UserFiltersInput {
  page: number;
  limit: number;
  sort: SortInput;
}

export interface VialInput {
  id?: string | null;
  productId: string;
  quantity: number;
  calibrationDate: any;
  freeReason?: TreatmentFreeReasonsEnum | null;
  lineItemNumber: any;
  _destroy?: boolean | null;
  activityAtDelivery?: string | null;
  inventorySource?: InventorySourceTypeEnum | null;
}

export interface VoidReasonFiltersInput {
  page: number;
  limit: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
