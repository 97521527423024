import gql from 'graphql-tag'

export const DEPOT_FRAGMENT = gql`
  fragment DepotFragment on Depot {
    __typename
    id
    name
  }
`

export const SHIPPING_GROUP_FRAGMENT = gql`
  fragment ShippingGroupFragment on ShippingGroup {
    __typename
    id
    name
  }
`

export const DEPOT_ELIGIBILITY_FRAGMENT = gql`
  fragment DepotEligibilityFragment on AddressDepotEligibility {
    __typename
    id
    depot {
      ...DepotFragment
    }
    eligibility
    shippingGroup {
      ...ShippingGroupFragment
    }
    priority
    depotCutOff
    depotCutOffTime
    lastMile
  }
  ${SHIPPING_GROUP_FRAGMENT}
  ${DEPOT_FRAGMENT}
`
