import gql from 'graphql-tag'
import { FULL_ADDRESS_FRAGMENT, CONTACT_FRAGMENT, DELIVERY_CHARGE_FRAGMENT } from '../fragments'

export const EDIT_ADDRESS_QUERY = gql`
  query EditAddress($id: ID!) {
    address(id: $id) {
      ...AddressFragment
      customer {
        id
        name
        regionCode
        businessType
        country
      }
    }
  }
  ${FULL_ADDRESS_FRAGMENT}
`

export const CUSTOMER_ADDRESS_DETAILS_QUERY = gql`
  query CustomerAddressDetails($id: ID!, $versionAt: DateTime) {
    address(id: $id, versionAt: $versionAt) {
      __typename
      id
      city
      country
      freightTerms
      incoterms
      incotermsLocation
      incotermsYear
      line1
      line2
      line3
      nordionShipToId
      regionCode
      state
      zip
      shippingNote
      mnxShippingNote
      attnTo
      attnTo2
      versionAt
      sapShipTo
      versionAt
      mnxCustomer
      mnxEligibleForRush
      deliveryCharges {
        ...DeliveryChargeFragment
      }
      contacts(notifiable: true) {
        ...ContactFragment
      }
      customer {
        __typename
        id
        financeId
        nordionAccountNumber
        name
        quote {
          __typename
          id
          reference
        }
        simplicityPerPlan
        sendOrdersToSap
      }
    }
  }
  ${CONTACT_FRAGMENT}
  ${DELIVERY_CHARGE_FRAGMENT}
`

export const CHECK_DUPLICATE_NRTS_QUERY = gql`
query CheckDuplicateNrts($shipToId: String!) {
  checkDuplicateNrts(shipToId: $shipToId)
}
`